require('bootstrap')

$(() => {
    InitModal();
    InitTeaser();
    InitForm();
})

const InitModal = () => {
    $("#newsletter-subscription-close").off('click').on('click', () => {
        $(".newsletter-subscription-modal-container").toggleClass("collapsed");
    });
}

const InitTeaser = () => {
    $(".newsletter-subscription-modal-teaser").off('click').on('click', () => {
        $(".newsletter-subscription-modal-container").toggleClass("collapsed");
    })
}

const InitForm = () => {
    $("#newsletter-subscription-submit").off('click').on('click', () => {
        const email = $("#newsletter-subscription-email").val();

        $.ajax({
            dataType: 'json',
            url: '/newslettersubscription/subscribe?email=' + email,
            type: 'POST'
        })
            .done(function (data) {
                if (data.success)
                {
                    window.location.replace('/newslettersubscription/subscribe')
                }
            });
    })
}